import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { styled } from '@mui/material/styles';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,CircularProgress , Grid , Avatar ,Box, Stack , Skeleton ,Paper,Table,TableHead, TableContainer,TableRow , TableBody , TableCell} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import GppGoodIcon from '@mui/icons-material/GppGood';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import PaymentScript from '../chargecards/PaymentScript';
import account from '../../_mock/account';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}));


const GetButton = styled('button')({
  boxShadow: '0px 5px 2px rgb(204, 204, 204)',
  border: 'none',
  textAlign: 'center',
});

export default function ChargeConfirmationModal({ handleClose, show, userData , packagee}) {
  const Navigate = useNavigate();
  const [paymentData, setPaymentData] = useState();
  const isInitialRender = useRef(true);
  const formRef = useRef(null);

  const [loading, setLoading] = React.useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };
  const getBundelsData = async () => {


    setLoading(true);
    const url = `${process.env.REACT_APP_API_KEY}/api/v1/files/deposit-kashier/${packagee.id}/`;
    const Body = {};
    axios
      .post(url, Body, config)
      .then((result) => {
        const responseUrl = result.data.redirect_iframe_url;
        setLoading(false);
        window.location.replace(responseUrl);
      
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(true);
  };


  // console.log(isInitialRender);
  useEffect(() => {
    if (isInitialRender.current) {
      const url = `${process.env.REACT_APP_API_KEY}/api/v1/files/deposit-kashiernew/${packagee.id}/`;
      const Body = {};

      axios.post(url, Body, config)
        .then(response => {
          setPaymentData(response.data);
          // console.log(response.data);
        })
        .catch(error => {
          console.error('Error fetching payment data', error);
        });
      isInitialRender.current = false;
    }
  }, []);
  // console.log(paymentData);


  useEffect(() => {
    if (paymentData) {
      const script = document.createElement('script');

      script.src = "https://checkout.kashier.io/kashier-checkout.js";
      // script.async = true;
      script.id = "kashier-iFrame";
      script.setAttribute('data-amount', paymentData.amount);
      script.setAttribute('data-hash', paymentData.hash);
      script.setAttribute('data-currency', paymentData.currency);
      script.setAttribute('data-orderId', paymentData.orderId);
      script.setAttribute('data-merchantId', paymentData.mid);
      script.setAttribute('data-merchantRedirect', 'https://gs.osoulworld.com/Graphic-Stock/thank-you');
      script.setAttribute('data-mode', 'live');
      script.setAttribute('data-metaData', '{"metaData":"myData"}');
      script.setAttribute('data-description', 'ORDER-DESCRIPTION');
      script.setAttribute('data-serverWebhook', 'https://api.osoulworld.com/api/v1/files/kashier-webhook/');

      script.setAttribute('data-redirectMethod', 'get');
      script.setAttribute('data-failureRedirect', 'false');
     // script.setAttribute('data-allowedMethods', 'card,wallet');
      script.setAttribute('data-type', 'external');
      script.setAttribute('data-brandColor', '#00bcbc');
      script.setAttribute('data-display', 'en');

      if (formRef.current) {
        formRef.current.appendChild(script);
      }
    return () => {
      if (formRef.current) {
        formRef.current.removeChild(script);
      }
        }
  }
  }, [paymentData]);



  const classes = useStyles();

  return (
    <Dialog open={show} onClose={handleClose} fullWidth maxWidth="lg"  >
      <DialogTitle color="rgb(0, 125, 125)">Purchase Confirmation</DialogTitle>
      <DialogContent>
  <Grid container spacing={3}>
    <Grid container item sm={12} md={6} alignItems="left" className='left' direction="column">
      <Stack direction="row" alignItems="center" borderBottom="2px solid gray" marginBottom="5px">
  {userInfo ? (
    <>
      <Avatar sx={{mr:1 , border:"gray solid 1px"}} alt={userInfo.firstName} src={`${process.env.REACT_APP_API_KEY}${userInfo.avatarUrl}`} className={classes.large} />
      <div>
        <Typography variant="h6" color="rgb(50, 50, 50)">{userData.first_name} {userData.last_name}</Typography>
        <Typography variant="body1">{userInfo.email}</Typography>
      </div>
    </>
  ) : (
    <>
      <Skeleton variant="circle" width={40} height={40} />
      <div>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
      </div>
    </>
  )}
  </Stack>
  <Stack direction="row" alignItems="center">
    <div>
    <Typography variant="body1" fontSize="1.3rem">
    👉 your current balance <span style={{ textDecoration: 'underline', color: 'red' }}>{userData.coins}</span> Coin
    </Typography>
    <Typography variant="body1" fontSize="1.3rem">
    👉  balance after purchase <span style={{ textDecoration: 'underline', color: 'green' }}>{userData.coins + packagee.coins}</span> Coin
    </Typography>
    </div>
    
  </Stack>
  </Grid>


<Grid item sm={12} md={6} className='right'>
    <TableContainer component={Paper}>
    <Table size="small" aria-label="a dense table">
      <caption style={{captionSide: 'top', color: 'white', backgroundColor: 'rgb(0, 165, 165)'}}>Your bill info</caption>
      <TableHead>
        <TableRow>
          <TableCell style={{width: '25%'}}>Description</TableCell>
          <TableCell align="left" style={{width: '15%'}}>Value</TableCell>
          <TableCell align="left" style={{width: '15%'}}>Price</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      <TableRow>
        <TableCell component="th" scope="row">
          {packagee.description}
        </TableCell>
        <TableCell align="left">{packagee.coins} Coin</TableCell>
        <TableCell align="left">{packagee.price} usd</TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row">
          Tax
        </TableCell>
        <TableCell align="left">0 %</TableCell>
        <TableCell align="left">0 USD</TableCell>
      </TableRow>
      <TableRow style={{backgroundColor: '#f5f5f5'  }}>
        <TableCell component="th" scope="row" align="center">
          Total
        </TableCell>
        <TableCell align="left"> </TableCell>
        <TableCell align="left">{packagee.price} USD</TableCell>
      </TableRow>
    </TableBody>
      </Table>
      <Typography variant="body2" style={{marginTop: '10px'}}>
        Note: You will pay the equivalent value in EGP.
      </Typography>
    </TableContainer>
  </Grid>
  </Grid>

</DialogContent>
<Stack paddingLeft="10px" minWidth="50%">
      <Typography variant="body2">
        <SyncDisabledIcon className={classes.icon} /> No need to pay every month
      </Typography>
      <Typography  variant="body2">
        <GppGoodIcon className={classes.icon} /> The purchase process is safe and guaranteed
      </Typography>
    </Stack>
  <DialogActions direction={{md:'row' , sm:'column'}}  >
     <div ref={formRef} /> 
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Close
          </Button>
</DialogActions>

</Dialog>
  );
  
  
              }  